@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Outfit:wght@100..900&display=swap');
*{
  margin: 0;
  padding: 0;
}
/* nav bar */
.navbar{
  background: #F8FBF8 !important;
}
.logo {
  width: 80px;
}
.navbar-scroll .nav-link,
.navbar-scroll .fa-bars,
.navbar-scroll .navar-brand {
  color:#000;
  font-size:18px;
  font-weight: 600;
}
.mymenu{
  color:#000;
  font-size:18px;
  font-weight: 600;
}
.mymenu:hover {
  color:#fff;
  font-weight: 700;
  background: #FFC107;
}
.slides{
  height: 650px;
}
/* slider */
.sbtn{
   width: 120px; 
   height:40px ;
   font-size: 18px;
   font-weight: 600;
}
/* banner */
.bannerbg{
  background: #00308F;
}
.cbtn{
  background:#FFC107 !important;
  border-top-right-radius: 20px !important;
  border-bottom-left-radius: 20px !important; 
  font-size: x-large !important;   
  font-weight: 600 !important; 
  color: #000 !important;
}
.cbtn:hover {
  background:#FFC107 !important;
  border-radius: 10px !important;
  font-size: x-large !important;   
  font-weight:700 !important; 
  color: #000 !important;
}
.bestfeatures{
  height: 150px;
}
.bestfeaturesc{
  background: #00308F;
  height: 150px;
}
.bestinner{
  width: 100px;
  height: 100px;
}
/* all headings */
h3,
h2,
.font-weight-bold {
  font-weight: 700 !important;
}
h3,
h4,
.font-weight-semi-bold {
  font-weight: 600 !important;
}
h5,
h6,
.font-weight-medium {
  font-weight: 500 !important;
}
@media only screen and (min-width: 361px) and (max-width: 768px) {
  h3,
  h2,
.font-weight-bold {
  font-weight: 600 !important;
  font-size: 14px;
}
h3,
h4,
.font-weight-semi-bold {
  font-weight: 400 !important;
  font-size: 14px;
}
h5,
h6,
.font-weight-medium {
  font-weight: 300 !important;
  font-size: 12px;
}
.bannertxt{
  font-size: 22px !important;
  text-align: center !important;
}
.conh{
  font-size: 20px !important;
}
.cbtn{
  background:#FFC107 !important;
  border-top-right-radius: 20px !important;
  border-bottom-left-radius: 20px !important; 
  font-size: 16px !important;   
  font-weight: 600 !important; 
  color: #000 !important;
}
.cbtn:hover {
  background:#FFC107 !important;
  border-radius: 10px !important;
  font-size: 18px !important;   
  font-weight:700 !important; 
  color: #000 !important;
}
.best{
  padding-top: 23px !important;
  padding-bottom: 23px !important;
}
.why{
  margin-top: 25px;
  padding-top: 25px;
}
.prow{
  padding-left:0px;
  padding-right: 0px;

}
.pwhy{
  padding-top: 15px;
}
.fcontent{
  margin: 20px;
}
.pin{
  padding-left: 23px;
}
.tplace{
  padding-top: 23px;
}
#servicehead{
  padding: 0px;
  margin: 0px;
}
.taxicol{
  padding-top: 23px;
}
}
.contactinfo{
background: #00308F;
 height: 400px;
}
.footerbg{
background: #00308F;
}
/* btn */
.btn-square {
  width: 36px;
  height: 36px;
}
.btn-sm-square {
  width: 28px;
  height: 28px;
}
.btn-lg-square {
  width: 46px;
  height: 46px;
}
.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}
/* services */
.service-item {
  background: var(--light);
  transition: .5s;
}
.service-item:hover,
.service-item.active {
  background: #00308F;
  color: #fff !important;
}
.service-item h3,
.service-item h4 {
  transition: .5s;
}
.service-item:hover h3,
.service-item.active h3 {
  color: var(--dark) !important;
}
.service-item:hover h4,
.service-item.active h4 {
  color: var(--light);
}
.rent-item {
  height: 400px;
  padding: 20px;
  text-align: center;
  background: var(--light);
  transition: .5s;
}
.rent-item:hover,
.rent-item.active {
  background: #00308F;
}
.rent-item h4 {
  transition: .5s;
}
.rent-item img{
  height: 250px;
}
.rent-item:hover h4,
.rent-item.active h4 ,
.rent-item:hover span ,
.rent-item.active span{
  color: #ffffff;
}
.fewinfo{
  color: #000;
  font-size: large;
  font-weight: 600;
}

/* banner1 */
.bg-banner{
  background: linear-gradient(rgba(6, 17, 137, 0.9), rgba(6, 17, 137, 0.9)), url('./assests/images/Traveller.png');
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}
.custom-line-height{
  line-height: 1.9;
}
/* mybtn */

/* map */
.gmap{
  border:0px;
  width: 100%;
  height: 450px;
}
/* placescardimg */
.placeimg{
  height: 350px;
}